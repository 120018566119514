import { FC, useState } from 'react';
import { PartI, taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { downloadFileFunc } from '../../../api/axiosCruds';
import edit from '../../../assets/editAdminIcon.svg';
import { useUpdateTaskMutation } from '../../../api/tenders';
import calendar from '../../../assets/Calendar, Schedule, Clock.svg';
import pendingIcon from '../../../assets/panding-circle.svg';

import attantion from '../../../assets/point.svg';
import messageGrey from '../../../assets/messageGrey.svg';
import SupplierIcon from '../../../assets/SupplierIcon.svg';
import downloadIcon from '../../../assets/Documents, File, Download.svg';
import { SupplierInfoModal } from '../../Modals/SupplierInfoModal';
import { ClockIcon } from '../../Icons/ClockIcon';
import { NoShowModal } from '../../Modals/NoShowModal';
import { Tooltip } from 'antd';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    messageApi: any;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setDeadlineModal: React.Dispatch<React.SetStateAction<boolean>>;
    isSuccess: boolean;
}

export const SupplierCard: FC<DefineSupportingDocumentTaskProps> = ({
    data,
    role,
    messageApi,
    setTrigger,
    setDeadlineModal,
    isSuccess
}) => {
    const [isSupplierInfoModal, setIsSupplierInfoModal] = useState<boolean>(false);
    const [taskId, setTaskId] = useState<number | null>(null);
    const [isEditSupplier, setIsEditSupplier] = useState(false);
    const [noShowModal, setNoShowModal] = useState(false);
    const [updateTask, { isLoading: isLoadingTookPlace }] = useUpdateTaskMutation();

    const updateTaskFunc = async (id: number) => {
        const formData = {
            ids: {
                tender_id: data?.id,
                task_id: id
            },
            data: { action: 'supplier_took_place' }
        };
        try {
            await updateTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const isToolTip = (className: string): boolean => {
        const element = document.querySelector(className);

        if (element) {
            const isTextOverflowing = element.scrollWidth > element.clientWidth;

            return isTextOverflowing;
        }

        return false;
    };

    const downloadFunc = (fileId: string, taskId: number) => {
        downloadFileFunc({
            tenderId: data.id,
            taskId: taskId,
            fileId: fileId
        })
            .then((response: any) => {
                const stplitArray = response.headers['content-disposition'].split(';');
                const element = stplitArray.find((elem: string) => elem.includes('filename'));
                const name = element.split('=')[1];
                downloadPDF(response, name);
            })
            .catch(() => {
                messageApi.error('Downloading Error. Check your internet connection');
            });
    };

    const downloadPDF = (response: any, filename: any) => {
        let blob = new Blob([response.data], {
            type: response.headers['content-type']
        });

        if (filename.includes('pdf')) {
            let headerLine = response.headers['content-disposition'];

            if (headerLine) {
                let startFileNameIndex = headerLine.indexOf('"') + 1;
                let endFileNameIndex = headerLine.lastIndexOf('"');
                filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
            }
        }

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = filename.replace(/"/g, '').trim();
        link.download = fileName;
        link.target = '_blank';

        const el = document.body;
        el.appendChild(link);

        link.click();
        setTrigger((prev: boolean) => !prev);
        messageApi.success('File has been downloaded successfully');
    };

    return (
        <>
            {data?.phase_tasks.length &&
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'evaluate_3_supplier') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' && !elem?.approved && !elem.participants?.length) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete 
                                                        `}
                                    >
                                        <div className="task-complete__title supplierCard">
                                            <div className="task-complete__title-row ">
                                                <img src={SupplierIcon} />
                                                <div className="supplier__name-info">
                                                    <div className="supplier__name">
                                                        {elem.name}
                                                        {elem.supplier_incumbent_status === 'yes' && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__email">{elem.supplier_email}</div>
                                                </div>
                                            </div>
                                            {role === 'tender_representative' && (
                                                <img
                                                    src={calendar}
                                                    className="task__plus"
                                                    style={{ margin: '0' }}
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setIsEditSupplier(false);
                                                        setIsSupplierInfoModal(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_tr' && !elem?.approved && elem.participants?.length) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row ">
                                                <img src={SupplierIcon} />
                                                <div className="supplier__name-info">
                                                    <div className="supplier__name">
                                                        {elem.name}
                                                        {elem.supplier_incumbent_status === 'yes' && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__email">{elem.supplier_email}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div
                                                    className="task-complete__name"
                                                    style={{ width: '140px', padding: '0' }}
                                                >
                                                    Meeting Date
                                                </div>
                                                <div className="task-complete__name">Time</div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div
                                                    className="task-complete__value"
                                                    style={{ width: '140px', padding: '0' }}
                                                >
                                                    <img src={calendar} />
                                                    {elem.meeting_slot?.[0].date}
                                                </div>
                                                <div className="task-complete__value">
                                                    <ClockIcon fill="#001965" style={{ margin: '0 8px 0 0' }} />
                                                    {elem.meeting_slot?.[0].time_from} -{' '}
                                                    {elem.meeting_slot?.[0].time_to}
                                                </div>
                                            </div>

                                            <div className="task-complete__wrap" style={{ margin: '10px 0 0 0' }}>
                                                <div className="task-complete__name" style={{ width: '70px' }}>
                                                    Participants{' '}
                                                </div>
                                            </div>
                                            <div className="task-complete__participants participants">
                                                {elem.participants.map((part: PartI) => {
                                                    return (
                                                        <div className="participants__participant">
                                                            <div className="participants__name tooltip">
                                                                {part.full_name}
                                                            </div>
                                                            {isToolTip('.tooltip') && (
                                                                <div className="hover-content">{part.full_name}</div>
                                                            )}
                                                            <div className="participants__title tooltip">
                                                                {part.title}
                                                            </div>{' '}
                                                            {isToolTip('.tooltip') && (
                                                                <div className="hover-content">{part.title}</div>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            {elem.files.tender_representative?.[0]?.name && (
                                                <div className="task-complete__info">
                                                    <div className="task-complete__wrap column">
                                                        <div className="task-complete__name">Uploaded File</div>
                                                        <div className="task-complete__value file">
                                                            {elem.files.tender_representative[0]?.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {elem.comments?.tender_representative && (
                                                <div className="task-complete__info">
                                                    <div className="task-complete__wrap column">
                                                        <div className="task-complete__name">My Comment</div>
                                                        <div className="task-complete__value">
                                                            {elem.comments.tender_representative}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {role !== 'super_admin' && (
                                                <img
                                                    src={edit}
                                                    className="task-complete__edit"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setIsEditSupplier(true);
                                                        setIsSupplierInfoModal(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            }

                            if (
                                (elem.status === 'need_approve_tr' || elem.status === 'pending_su') &&
                                !elem?.approved
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            elem.supplier_took_place === 'yes' || elem.supplier_no_show === 'yes'
                                                ? 'green'
                                                : ''
                                        }`}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row ">
                                                <img src={SupplierIcon} />
                                                <div className="supplier__name-info">
                                                    <div className="supplier__name">
                                                        {elem.name}
                                                        {elem.supplier_incumbent_status === 'yes' && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__email">{elem.supplier_email}</div>
                                                </div>
                                            </div>
                                            <div>
                                                {role !== 'super_admin' && (
                                                    <div className="tasks__checkbox-wrapper">
                                                        <div className="tasks__checkbox-container">
                                                            <div
                                                                className={`tasks__checkbox ${
                                                                    elem.supplier_no_show === 'yes' ? 'active' : ''
                                                                }`}
                                                                onClick={() => {
                                                                    if (elem.supplier_no_show === 'yes') return;
                                                                    setTaskId(elem.id);
                                                                    setNoShowModal(true);
                                                                }}
                                                            ></div>
                                                            <div>No Show</div>
                                                        </div>
                                                        <div
                                                            className="tasks__checkbox-container"
                                                            onClick={() => {
                                                                if (
                                                                    isLoadingTookPlace ||
                                                                    elem.supplier_took_place === 'yes'
                                                                )
                                                                    return;
                                                                updateTaskFunc(elem.id);
                                                            }}
                                                        >
                                                            <div
                                                                className={`tasks__checkbox ${
                                                                    elem.supplier_took_place === 'yes' ? 'active' : ''
                                                                }`}
                                                            ></div>
                                                            <div>Took Place</div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name" style={{ width: '180px' }}>
                                                    Meeting Date
                                                </div>
                                                <div className="task-complete__name">Time</div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value" style={{ width: '180px' }}>
                                                    <img src={calendar} />
                                                    {elem.meeting_slot[0].date}
                                                </div>
                                                <div className="task-complete__value">
                                                    <ClockIcon fill="#001965" />
                                                    {elem.meeting_slot[0].time_from} - {elem.meeting_slot[0].time_to}
                                                </div>
                                            </div>

                                            <div className="task-complete__wrap" style={{ margin: '10px 0 0 0' }}>
                                                <div className="task-complete__name" style={{ width: '70px' }}>
                                                    Participants{' '}
                                                </div>
                                            </div>
                                            <div className="task-complete__participants participants">
                                                {elem.participants.map((part: PartI) => {
                                                    return (
                                                        <div className="participants__participant">
                                                            <div className="participants__name">{part.full_name}</div>
                                                            <div className="participants__title">{part.title}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            {elem.files?.tender_representative?.[0]?.name && (
                                                <div className="task-complete__info">
                                                    <div className="task-complete__wrap column">
                                                        <div className="task-complete__name">Uploaded File</div>
                                                        <div className="task-complete__value file">
                                                            {elem.files.tender_representative[0]?.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {elem.comments.tender_representative && (
                                                <div className="task-complete__info">
                                                    <div className="task-complete__wrap column">
                                                        <div className="task-complete__name">My Comment</div>
                                                        <div className="task-complete__value">
                                                            {elem.comments.tender_representative}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                        }
                        if (role === 'school_user') {
                            if (elem.status === 'pending_su' && !elem?.approved && elem.participants?.length) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row ">
                                                <img src={SupplierIcon} />
                                                <div className="supplier__name-info">
                                                    <div className="supplier__name">
                                                        {elem.name}
                                                        {elem.supplier_incumbent_status === 'yes' && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__email">{elem.supplier_email}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name" style={{ width: '180px' }}>
                                                    Meeting Date
                                                </div>
                                                <div className="task-complete__name">Time</div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value" style={{ width: '180px' }}>
                                                    <img src={calendar} />
                                                    {elem.meeting_slot?.[0].date}
                                                </div>
                                                <div className="task-complete__value">
                                                    <ClockIcon fill="#001965" />
                                                    {elem.meeting_slot?.[0].time_from} -{' '}
                                                    {elem.meeting_slot?.[0].time_to}
                                                </div>
                                            </div>

                                            <div className="task-complete__wrap" style={{ margin: '10px 0 0 0' }}>
                                                <div className="task-complete__name" style={{ width: '70px' }}>
                                                    Participants{' '}
                                                </div>
                                            </div>
                                            <div className="task-complete__participants participants">
                                                {elem.participants.map((part: PartI) => {
                                                    return (
                                                        <div className="participants__participant">
                                                            <div className="participants__name">{part.full_name}</div>
                                                            <div className="participants__title">{part.title}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            {elem.files?.tender_representative?.[0]?.name && (
                                                <>
                                                    <div className="task-complete__info" style={{ display: 'flex' }}>
                                                        <div className="task-complete__wrap column">
                                                            <div className="task-complete__name">Received File</div>
                                                            <div
                                                                className="task-complete__value file"
                                                                style={{ width: '100%' }}
                                                            >
                                                                {elem.files?.tender_representative[0]?.name}
                                                            </div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div
                                                                className={`task-complete__download-btn `}
                                                                onClick={() => {
                                                                    downloadFunc(
                                                                        elem.files.tender_representative[0]?.uuid,
                                                                        elem.id
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={downloadIcon}
                                                                    className="upload-download-icon"
                                                                />
                                                                Download
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {elem.comments.tender_representative && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments.tender_representative}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            if (
                                (elem.status === 'need_approve_tr' || elem.status === 'pending_tr') &&
                                !elem?.approved &&
                                elem.participants?.length
                            ) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row ">
                                                <img src={SupplierIcon} />
                                                <div className="supplier__name-info">
                                                    <div className="supplier__name">
                                                        {elem.name}
                                                        {elem.supplier_incumbent_status === 'yes' && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__email">{elem.supplier_email}</div>
                                                </div>
                                            </div>
                                            <div className="task-complete__title-row">
                                                {' '}
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name" style={{ width: '180px' }}>
                                                    Meeting Date
                                                </div>
                                                <div className="task-complete__name">Time</div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value" style={{ width: '180px' }}>
                                                    <img src={calendar} />
                                                    {elem.meeting_slot?.[0].date}
                                                </div>
                                                <div className="task-complete__value">
                                                    <ClockIcon fill="#001965" />
                                                    {elem.meeting_slot?.[0].time_from} -{' '}
                                                    {elem.meeting_slot?.[0].time_to}
                                                </div>
                                            </div>

                                            <div className="task-complete__wrap" style={{ margin: '10px 0 0 0' }}>
                                                <div className="task-complete__name" style={{ width: '70px' }}>
                                                    Participants{' '}
                                                </div>
                                            </div>
                                            <div className="task-complete__participants participants">
                                                {elem.participants.map((part: PartI) => {
                                                    return (
                                                        <div className="participants__participant">
                                                            <div className="participants__name">{part.full_name}</div>
                                                            <div className="participants__title">{part.title}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            {elem.files?.tender_representative?.[0]?.name && (
                                                <>
                                                    <div className="task-complete__info" style={{ display: 'flex' }}>
                                                        <div className="task-complete__wrap column">
                                                            <div className="task-complete__name">Received File</div>
                                                            <div
                                                                className="task-complete__value file"
                                                                style={{ width: '100%' }}
                                                            >
                                                                {elem.files?.tender_representative[0]?.name}
                                                            </div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div
                                                                className={`task-complete__download-btn `}
                                                                onClick={() => {
                                                                    downloadFunc(
                                                                        elem.files.tender_representative[0]?.uuid,
                                                                        elem.id
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={downloadIcon}
                                                                    className="upload-download-icon"
                                                                />
                                                                Download
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {elem.comments.tender_representative && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments.tender_representative}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                })}

            {isSupplierInfoModal && (
                <SupplierInfoModal
                    openModal={isSupplierInfoModal}
                    setOpenModal={setIsSupplierInfoModal}
                    messageApi={messageApi}
                    data={data}
                    setTrigger={setTrigger}
                    taskId={taskId}
                    isEditSupplier={isEditSupplier}
                />
            )}
            {noShowModal && (
                <NoShowModal
                    openModal={noShowModal}
                    setOpenModal={setNoShowModal}
                    setTrigger={setTrigger}
                    taskId={taskId}
                    data={data}
                    setDeadlineModal={setDeadlineModal}
                    isSuccess={isSuccess}
                />
            )}
        </>
    );
};
