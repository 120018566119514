import React, { useEffect, useState } from 'react';
import { Button, Modal, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import i from '../../assets/i.svg';
import plus from '../../assets/Plus, Add.svg';
import { useAppSelector } from '../../hooks';
import dropfile1 from '../../assets/drop field 1.png';
import dropfile3 from '../../assets/drop field 3.png';
import dropfile2 from '../../assets/drop field 2.png';

const { Dragger } = Upload;

interface FilePickerProps {
    fileList: any[];
    onChange: any;
    error: string;
    setError: any;
    disabled?: boolean;
    title?: string;
    specialFormats?: string[];
    className?: string;
}

export const FilePicker: React.FC<FilePickerProps> = ({
    fileList,
    onChange,
    error,
    setError,
    disabled,
    title,
    specialFormats,
    className
}) => {
    const [confirmModal, setConfirmModal] = useState(false);
    const { role } = useAppSelector((state) => state.profileReducer);

    const handleFileChange = ({ fileList }: UploadChangeParam<any>) => {
        const maxSizeBytes = 100 * 1024 * 1024;
        let isValid = false;

        if (specialFormats) {
            specialFormats.forEach((item: string) => {
                if (fileList[0].name.split('.').pop() === item && fileList[0].size < maxSizeBytes) {
                    isValid = true;
                }
            });
            if (fileList.length <= 0 || !isValid) {
                setError(
                    `You can upload files in the following formats: ${specialFormats
                        .map((item: string) => item)
                        .join(', ')} with a maximum size of 100MB`
                );
            } else {
                setError('');
            }
        } else {
            if (role === 'school_user') {
                if (
                    (fileList[0].name.split('.').pop() === 'pdf' ||
                        fileList[0].name.split('.').pop() === 'jpg' ||
                        fileList[0].name.split('.').pop() === 'jpeg' ||
                        fileList[0].name.split('.').pop() === 'heic' ||
                        fileList[0].name.split('.').pop() === 'png' ||
                        fileList[0].name.split('.').pop() === 'doc' ||
                        fileList[0].name.split('.').pop() === 'zip' ||
                        fileList[0].name.split('.').pop() === 'xlsx' ||
                        fileList[0].name.split('.').pop() === 'xls' ||
                        fileList[0].name.split('.').pop() === 'ppt' ||
                        fileList[0].name.split('.').pop() === 'pptx' ||
                        fileList[0].name.split('.').pop() === 'docx') &&
                    fileList[0].size < maxSizeBytes
                ) {
                    isValid = true;
                }
            } else {
                if (
                    (fileList[0].name.split('.').pop() === 'pdf' ||
                        fileList[0].name.split('.').pop() === 'elsx' ||
                        fileList[0].name.split('.').pop() === 'doc' ||
                        fileList[0].name.split('.').pop() === 'docx' ||
                        (title?.includes('Zip') && fileList[0].name.split('.').pop() === 'zip')) &&
                    fileList[0].size < maxSizeBytes
                ) {
                    isValid = true;
                }
            }
            if (fileList.length <= 0 || !isValid) {
                setError('You can upload files in the following formats: doc, pdf with a maximum size of 100MB');
            } else {
                setError('');
            }
        }

        onChange(fileList);
    };

    const deleteFile = () => {
        onChange([]);
        setError('');
    };

    return (
        <>
            {fileList.length ? (
                <div className={`uploaded-wrapper `}>
                    {!error && <img src={dropfile3} className="uploaded-wrapper__image" />}
                    {error && <img src={dropfile2} className="uploaded-wrapper__image" />}
                    <div className="uploaded-wrapper__file">
                        <div
                            className="uploaded-file"
                            style={error ? { margin: '20px 20px 0px 20px' } : { margin: '15px 20px' }}
                        >
                            {fileList.map((elem: any) => {
                                return elem.name;
                            })}
                        </div>
                        {!disabled && (
                            <img src={plus} onClick={() => setConfirmModal(true)} className="uploaded-wrapper__plus" />
                        )}
                    </div>
                    <div className="uploaded-wrapper__error">{error}</div>
                </div>
            ) : (
                <>
                    <Dragger
                        disabled={disabled}
                        fileList={fileList}
                        onChange={handleFileChange}
                        customRequest={() => {}}
                        showUploadList={true}
                        className={className}
                    >
                        <div className="upload-wrapper">
                            <img src={dropfile1} className="upload-wrapper__image" />
                            <div className="ant-upload-text">
                                Drop your file here or <span>Browse</span>
                            </div>
                            <div className="ant-upload-subtext">
                                {title ? (
                                    title
                                ) : (
                                    <>
                                        {specialFormats &&
                                            `${specialFormats.map((item: string) => item).join(', ')}(max. 100 MB)`}
                                        {!specialFormats
                                            ? role === 'school_user'
                                                ? 'pdf, jpg, jpeg, heic, png, doc, xls, ppt, zip (max. 100 MB)'
                                                : 'Doc, Pdf (max. 100 MB)'
                                            : ''}
                                    </>
                                )}
                            </div>
                            {error && <div className="upload-wrapper__error">{error}</div>}
                        </div>
                    </Dragger>
                </>
            )}
            {confirmModal && (
                <Modal
                    className={''}
                    centered
                    open={confirmModal}
                    closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                    width={600}
                    footer={
                        <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                            <Button
                                style={{ width: '50%' }}
                                key="back"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setConfirmModal(false);
                                }}
                                block
                                className="cancel"
                            >
                                Cancel
                            </Button>
                            <button
                                style={{ width: '50%' }}
                                className="quit"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    deleteFile();
                                    setConfirmModal(false);
                                }}
                            >
                                Delete
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">Are you sure you want to remove the file?</div>
                </Modal>
            )}
        </>
    );
};
