import { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Modal, Spin } from 'antd';
import type { SelectProps } from 'antd';
import './index.scss';
import { useClickOutside } from '../../hooks/useClickOutSide';
import search from '../../assets/Search.svg';
import schoolImage from '../../assets/schoolImage.svg';
import arrow from '../../assets/arrow.svg';
import close from '../../assets/Close.svg';
import noResult from '../../assets/noResult.svg';
import Plus from '../../assets/Plus, Add.svg';
import file from '../../assets/file.svg';
import disabledFile from '../../assets/disabledFile.svg';
import editAdmin from '../../assets/editAdminIcon.svg';
import deleteIcon from '../../assets/DeleteIcon.svg';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import Edit from '../Icons/Edit';

interface OptionType {
    key?: string;
    name?: string;
    id?: number | string;
    value?: string;
}

interface FloatSelectProps extends SelectProps {
    required: boolean;
    floatLabel: string;
    hasError: () => boolean;
    setSearch?: React.Dispatch<React.SetStateAction<string>>;
    options: any;
    onChange?: any;
    search?: string;
    setChanged: React.Dispatch<React.SetStateAction<boolean>>;
    create: any;
    edit: any;
    newAreaValue: string;
    setNewAreaValue: React.Dispatch<React.SetStateAction<string>>;
    success: boolean;
    error: boolean;
    errorTextUpdate: string;
    errorTextCreate: string;
    deleteFunc: any;
    loading: boolean;
    successupdate: boolean;
}

export const ExpenseAreaSelect = (props: FloatSelectProps) => {
    const [openSelect, setOpenSelect] = useState<boolean>(false);
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    let {
        floatLabel,
        placeholder,
        value,
        required,
        hasError,
        setSearch,
        options,
        onChange,
        search,
        setChanged,
        create,
        edit,
        deleteFunc,
        newAreaValue,
        setNewAreaValue,
        success,
        error,
        errorTextUpdate,
        errorTextCreate,
        loading,
        successupdate,
        ...restProps
    } = props;
    if (!placeholder) placeholder = floatLabel;

    useClickOutside(ref, () => setOpenSelect(false));
    const isOccupied = value;
    const labelClass = isOccupied ? 'label as-label-area' : 'label as-placeholder';
    <img src={value ? '' : arrow} className={'image'} />;
    const [addNewOption, setAddNewOption] = useState(false);
    const [editItem, setEditItem] = useState(false);
    const [editedAreaValue, setEditedAreaValue] = useState('');
    const [errorForOptinCreate, setErrorForOptionCreate] = useState('');
    const [errorForOptinUpdate, setErrorForOptionUpdate] = useState('');

    useEffect(() => {
        if (!options || !successupdate) return;
        if (editItem === value?.id) {
            const element = { ...value, name: editedAreaValue };
            onChange(element);
        }
    }, [successupdate]);

    useEffect(() => {
        if (isConfirmDeleteModal) {
            if (isConfirmDeleteModal === value?.id) {
                onChange(null);
            }
        }
    }, [isConfirmDeleteModal]);

    useEffect(() => {
        if (errorTextUpdate) {
            setErrorForOptionUpdate(errorTextUpdate);
        } else {
            setErrorForOptionUpdate('');
        }
    }, [errorTextUpdate]);

    useEffect(() => {
        if (errorTextCreate) {
            setErrorForOptionCreate(errorTextCreate);
        } else {
            setErrorForOptionCreate('');
        }
    }, [errorTextCreate]);

    useEffect(() => {
        if (!openSelect) {
            setEditItem(false);
            setEditedAreaValue('');
            setAddNewOption(false);
            setNewAreaValue('');
            setErrorForOptionCreate('');
            setErrorForOptionUpdate('');
        }
    }, [openSelect]);

    useEffect(() => {
        if (success || successupdate) {
            setAddNewOption(false);
            setNewAreaValue('');
            setEditedAreaValue('');
            setEditItem(false);
            setErrorForOptionCreate('');
            setErrorForOptionUpdate('');
        }
    }, [success, successupdate]);

    return (
        <>
            <div className="float-label" ref={ref}>
                <div
                    className={`${openSelect ? 'customSelect' : 'input-select'} ${hasError() && !value ? 'err' : ''}`}
                    onClick={() => {
                        if (!openSelect) {
                            setOpenSelect(true);
                        }
                    }}
                >
                    <img
                        src={value ? close : arrow}
                        className={'image'}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (value) {
                                onChange(null);
                                setChanged(true);
                                setOpenSelect(false);
                            } else {
                                setOpenSelect((prev) => !prev);
                            }
                        }}
                    />
                    <label className={labelClass}>
                        {isOccupied ? floatLabel : placeholder} {required && <span className="red">*</span>}
                    </label>

                    {value && (
                        <div className="select-option result-area">
                            <div className="select-option__wrap">
                                <div className="select-option__name">{value.value ? value.value : value.name}</div>
                            </div>
                        </div>
                    )}

                    {openSelect && (
                        <>
                            <div className="customSelect__options area">
                                {options?.length ? (
                                    options?.map((elem: any) => {
                                        return (
                                            <>
                                                {editItem === elem.id ? (
                                                    <div className="customSelect__add-input add-input" key={elem.id}>
                                                        <Form.Item>
                                                            <Input
                                                                placeholder="Area Name"
                                                                className={`additional-input ${
                                                                    errorForOptinUpdate ? 'additional-input-err' : ''
                                                                }`}
                                                                maxLength={60}
                                                                value={editedAreaValue}
                                                                onChange={(e) => {
                                                                    setErrorForOptionUpdate('');
                                                                    setEditedAreaValue(e.target.value);
                                                                }}
                                                            />
                                                            <img //edit option
                                                                src={editedAreaValue ? file : disabledFile}
                                                                className="add-input__file"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (!editedAreaValue) return;
                                                                    setErrorForOptionUpdate('');
                                                                    edit(editItem, editedAreaValue);
                                                                }}
                                                            />
                                                            <img //close editeble option
                                                                src={close}
                                                                className="add-input__close"
                                                                onClick={() => {
                                                                    if (editedAreaValue !== elem.name) {
                                                                        setIsConfirmModal(true);
                                                                    } else {
                                                                        setEditItem(false);
                                                                    }
                                                                }}
                                                            />
                                                        </Form.Item>

                                                        <div className="additional-input-errtext">
                                                            {errorForOptinUpdate}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        key={elem.id}
                                                        className="select-option"
                                                        onClick={() => {
                                                            onChange(elem);
                                                            setOpenSelect(false);
                                                        }}
                                                        style={{ justifyContent: 'space-between' }}
                                                    >
                                                        <div className="select-option__wrap">
                                                            <div className="select-option__name">
                                                                {elem.value ? elem.value : elem.name}
                                                            </div>
                                                        </div>
                                                        <div className="select-option__wrap-imgs">
                                                            <Edit
                                                                fill="#4D5E93"
                                                                className="select-option__btn edit"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setEditItem(elem.id);
                                                                    setEditedAreaValue(elem.name);
                                                                    setAddNewOption(false);
                                                                }}
                                                            />
                                                            <img
                                                                src={deleteIcon}
                                                                className="select-option__btn"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (elem.active_tenders_count) {
                                                                        deleteFunc(elem.id);
                                                                    } else {
                                                                        setIsConfirmDeleteModal(elem.id);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        );
                                    })
                                ) : (
                                    <div className="customSelect__no-result">
                                        <img src={noResult} />
                                        <div>No results found</div>
                                    </div>
                                )}
                            </div>
                            <div className="add-an-option">
                                {addNewOption && (
                                    <div className="customSelect__add-input add-input">
                                        <Form.Item name="search">
                                            <Input
                                                placeholder="Area Name"
                                                className={`additional-input ${
                                                    errorForOptinCreate ? 'additional-input-err' : ''
                                                }`}
                                                maxLength={60}
                                                value={newAreaValue}
                                                onChange={(e) => {
                                                    setErrorForOptionCreate('');
                                                    setNewAreaValue(e.target.value);
                                                }}
                                            />
                                            <img
                                                src={newAreaValue ? file : disabledFile}
                                                className="add-input__file"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (!newAreaValue) return;
                                                    create();
                                                }}
                                            />
                                            <img
                                                src={close}
                                                className="add-input__close"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (newAreaValue) {
                                                        setIsConfirmModal(true);
                                                    } else {
                                                        setErrorForOptionCreate('');
                                                        setAddNewOption(false);
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                        <div className="additional-input-errtext">{errorForOptinCreate}</div>
                                    </div>
                                )}
                                <div
                                    className="customSelect__addOption"
                                    style={addNewOption ? { margin: '-21px 0 0 0' } : { margin: '0 0 0 0' }}
                                    onClick={() => {
                                        setAddNewOption(true);
                                        setEditItem(false);
                                    }}
                                >
                                    <img src={Plus} />
                                    Add an option
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <Modal
                className={''}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            key="back"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsConfirmModal(false);
                            }}
                            block
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={(e) => {
                                e.stopPropagation();
                                setAddNewOption(false);
                                setNewAreaValue('');
                                setIsConfirmModal(false);
                                setEditItem(false);
                                setErrorForOptionCreate('');
                                setErrorForOptionUpdate('');
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Are you sure you want to abandon your changes to the tender?</div>
            </Modal>
            <Modal
                className={''}
                centered
                open={isConfirmDeleteModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmDeleteModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={(e) => {
                                e.stopPropagation();
                                deleteFunc(isConfirmDeleteModal);
                                setIsConfirmDeleteModal(false);
                                setOpenSelect(true);
                            }}
                        >
                            Delete
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Are you sure you want to delete the expense area?</div>
            </Modal>
        </>
    );
};
