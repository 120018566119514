import { FC, useEffect, useState } from 'react';
import { Button, Divider, Form, Modal, Tooltip } from 'antd';
import { Loader } from '../Loader/Loader';
import { Select } from '../Fields/Select';
import close from '../../assets/RoundedClose.svg';
import { ClockIcon } from '../Icons/ClockIcon';
import { PeopleIcon } from '../Icons/PeopleIcon';
import { FloatTextArea } from '../Fields/FloatTextArea';
import addslotIcon from '../../assets/Calendar, Schedule, Add.svg';
import { DatePickerInput } from '../Fields/DatePickerInput';
import i from '../../assets/i.svg';
import clock from '../../assets/clock.svg';
import Delete from '../Icons/Delete';
import { useUpdateTaskMutation } from '../../api/tenders';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import moment from 'moment';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    TenderData: tenderDataProps;
    messageApi: any;
    supplirsLength: number;
}

interface TimeObject {
    value: string | null;
    id: string | null;
}

interface SlotI {
    date: string;
    time_from: any;
    time_to: any;
}

interface dataI {
    duration: null | number;
    representatives_count: null | number;
    specific_questions: string;
    arrival_point: string;
    slots: SlotI[];
    campus_tour: {
        date: string;
        time_from: any;
        time_to: any;
        instructions: string;
    };
}

export const PitchForm: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    TenderData,
    messageApi,
    setTrigger,
    supplirsLength
}) => {
    const [form] = Form.useForm();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [data, setData] = useState<dataI>({
        duration: 60,
        representatives_count: 2,
        specific_questions: '',
        arrival_point: '',
        slots: [],
        campus_tour: {
            date: '',
            time_from: '',
            time_to: '',
            instructions: ''
        }
    });
    const [errors, setErrors] = useState<number[]>([]);
    const [errorsFields, setErrorsFields] = useState<boolean>(false);
    const [timeToArrays, setTimeToArrays] = useState<TimeObject[][]>([]);
    const [timeToArraysCT, setTimeToArraysCT] = useState<TimeObject[][]>([]);
    const [countOfSlotsError, setCountOfSlotsError] = useState(false);
    const [campusTourError, setCampusTourError] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const [updateTask, { data: updatedData, isSuccess, isError, error, isLoading }] = useUpdateTaskMutation();

    const timeArray: TimeObject[] = generateTimeArray();

    console.log('data', data);

    const scrollToElementById = (elementId: string) => {
        const element = document.getElementById(elementId);

        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        }
    };

    useEffect(() => {
        if (isSuccess) {
            messageApi.success(updatedData?.message);
            setOpenModal(false);
            setTrigger((prev: boolean) => !prev);
        }
    }, [isSuccess]);

    const generateDependentArray = (baseTime: string | null, isCumpusTour: boolean = false) => {
        const indexElem = timeArray.findIndex((elem: TimeObject) => {
            //@ts-ignore
            return elem.value === baseTime?.value;
        });
        const newArray: any = timeArray.slice(indexElem + 1);

        newArray.push({ value: '17:00', id: '17:00' });

        if (isCumpusTour) {
            setTimeToArraysCT(newArray);
        } else {
            setTimeToArrays(newArray);
        }
    };

    const handleFormSubmit = () => {
        let isValid = true;
        //check slots
        const slotsWithErrors: number[] = [];
        data.slots?.forEach((elem: SlotI, index: number) => {
            if (!elem.date || !elem.time_from || !elem.time_to) {
                isValid = false;
                slotsWithErrors.push(index);
            }
        });
        setErrors(slotsWithErrors);

        //check arrival point
        if (!data.arrival_point.trim()) {
            isValid = false;
            setErrorsFields(true);
            scrollToElementById('arrival');
        }

        //check count of slost
        if (data.slots.length < supplirsLength) {
            isValid = false;
            setCountOfSlotsError(true);
        }

        //check campus tour
        if (!data.campus_tour.date || !data.campus_tour.time_from || !data.campus_tour.time_to) {
            isValid = false;
            setCampusTourError(true);
            scrollToElementById('campus');
        }

        if (isValid) {
            const transformedData = {
                ...data,
                specific_questions: data.specific_questions.trim(),
                slots: data.slots.map((slot) => ({
                    date: moment(slot.date).format('YYYY-MM-DD'),
                    time_from: slot.time_from?.value && slot.time_from?.value.split(' ')[0],
                    time_to: slot.time_to?.value && slot.time_to?.value.split(' ')[0]
                })),
                campus_tour: {
                    date: moment(data.campus_tour.date).format('YYYY-MM-DD'),
                    time_from: data.campus_tour.time_from?.value && data.campus_tour.time_from.value.split(' ')[0],
                    time_to: data.campus_tour.time_to?.value && data.campus_tour.time_to.value.split(' ')[0],
                    instructions: data.campus_tour.instructions
                }
            };

            const dataToPost = {
                ids: {
                    tender_id: TenderData.id,
                    task_id: TenderData.phase_tasks?.[2].id
                },
                data: transformedData
            };
            updateTask(dataToPost);
        } else {
            setData((prevState) => ({
                ...prevState,
                slots: prevState.slots.map((slot, index) => ({
                    ...slot,
                    hasError: slotsWithErrors.includes(index)
                }))
            }));
        }
    };

    const setSlotsFunc = () => {
        setIsDirty(true);
        setCountOfSlotsError(false);
        const newSlot = {
            date: '',
            time_from: null,
            time_to: null
        };

        setData((prevState: dataI) => ({
            ...prevState,
            slots: [...prevState.slots, newSlot]
        }));
    };

    const deleteSlot = (index: number) => {
        setIsDirty(true);
        const array = [...timeToArrays];
        array.splice(index, 1);
        setTimeToArrays(array);

        setData((prevState: dataI) => ({
            ...prevState,
            slots: prevState.slots.filter((_, i) => i !== index)
        }));
    };

    const handleInputChange = (index: number, data: string, name: string) => {
        setIsDirty(true);
        if (name === 'time_from') {
            generateDependentArray(data);
        }
        const indexToRemove = errors.indexOf(index);
        const newErrors = [...errors];

        if (indexToRemove !== -1) {
            newErrors.splice(indexToRemove, 1);
            setErrors(newErrors);
        }

        setData((prevState) => {
            const updatedSlots: any = [...prevState.slots];
            if (name === 'time_from') {
                updatedSlots[index].time_to = '';
            }

            updatedSlots[index][name] = data;

            return { ...prevState, slots: updatedSlots };
        });
    };

    function generateTimeArray(): TimeObject[] {
        const timeArray: TimeObject[] = [
            { value: '07:00', id: '07:00' },
            { value: '07:15', id: '07:15' },
            { value: '07:30', id: '07:30' },
            { value: '07:45', id: '07:45' },
            { value: '08:00', id: '08:00' },
            { value: '08:15', id: '08:15' },
            { value: '08:30', id: '08:30' },
            { value: '08:45', id: '08:45' },
            { value: '09:00', id: '09:00' },
            { value: '09:15', id: '09:15' },
            { value: '09:30', id: '09:30' },
            { value: '09:45', id: '09:45' },
            { value: '10:00', id: '10:00' },
            { value: '10:15', id: '10:15' },
            { value: '10:30', id: '10:30' },
            { value: '10:45', id: '10:45' },
            { value: '11:00', id: '11:00' },
            { value: '11:15', id: '11:15' },
            { value: '11:30', id: '11:30' },
            { value: '11:45', id: '11:45' },
            { value: '12:00', id: '12:00' },
            { value: '12:15', id: '12:15' },
            { value: '12:30', id: '12:30' },
            { value: '12:45', id: '12:45' },
            { value: '13:00', id: '13:00' },
            { value: '13:15', id: '13:15' },
            { value: '13:30', id: '13:30' },
            { value: '13:45', id: '13:45' },
            { value: '14:00', id: '14:00' },
            { value: '14:15', id: '14:15' },
            { value: '14:30', id: '14:30' },
            { value: '14:45', id: '14:45' },
            { value: '15:00', id: '15:00' },
            { value: '15:15', id: '15:15' },
            { value: '15:30', id: '15:30' },
            { value: '15:45', id: '15:45' },
            { value: '16:00', id: '16:00' },
            { value: '16:15', id: '16:15' },
            { value: '16:30', id: '16:30' },
            { value: '16:45', id: '16:45' }
        ];
        return timeArray;
    }

    const is2WeekDisabled = (date: Date) => {
        const isSunday = (date: Date) => {
            return date.getDay() === 0 || date.getDay() === 6;
        };
        const today = new Date();
        const twoWeeksLater = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);
        return !isSunday(date) && date >= twoWeeksLater;
    };

    const is1WeekDisabled = (date: Date) => {
        const isSunday = (date: Date) => {
            return date.getDay() === 0 || date.getDay() === 6;
        };
        const today = new Date();
        const twoWeeksLater = new Date(today.getTime() + 6 * 24 * 60 * 60 * 1000);
        return !isSunday(date) && date >= twoWeeksLater;
    };

    useEffect(() => {
        if (!TenderData?.phase_tasks?.[2].duration) return;
        const pitchData = TenderData?.phase_tasks?.[2];
        setData({
            duration: pitchData.duration ? +pitchData.duration : null,
            representatives_count: pitchData.representatives_count ? +pitchData.representatives_count : null,
            specific_questions: pitchData.specific_questions,
            arrival_point: pitchData.arrival_point,
            campus_tour: {
                date: pitchData.campus_tour.date,
                time_from: { value: pitchData.campus_tour.time_from, id: pitchData.campus_tour.time_from },
                time_to: { value: pitchData.campus_tour.time_to, id: pitchData.campus_tour.time_to },
                instructions: pitchData.campus_tour.instructions
            },
            slots: pitchData.slots.map((elem: SlotI) => {
                return {
                    date: elem.date,
                    time_from: { value: elem.time_from, id: elem.time_from },
                    time_to: { value: elem.time_to, id: elem.time_to }
                };
            })
        });
    }, [TenderData]);

    return (
        <>
            <Modal
                className="modal-pitch"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (isDirty) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (isLoading) return;
                                if (isDirty) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            {isLoading ? <Loader /> : 'Confirm'}
                        </Button>
                    </div>
                }
            >
                <div className="modal pitch">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">Evaluation Gate 3 Availability Form</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__pitchtitle">Provide available slot for Campus Tour (Optional)</div>

                        <div className="slot-list" id="campus">
                            <div className={`slot ${campusTourError ? 'error' : ''}`}>
                                <div className="slot__title">
                                    Campus Tour Date{' '}
                                    <Tooltip
                                        title={
                                            <div>
                                                We recommend hosting campus tours for service-related tenders (such as
                                                security, cleaning, or landscaping) while tenders for goods (e.g.,
                                                school wear, textbooks, or stationery) do not typically require one.
                                                Please note that the campus tour will be conducted in a single session
                                                with all shortlisted suppliers present, with each supplier limited to
                                                two representatives.
                                            </div>
                                        }
                                        overlayClassName={'custom-tooltip-disabled'}
                                    >
                                        <img src={i} className="i" />
                                    </Tooltip>
                                </div>
                                <Form className="slot__form">
                                    <Form.Item className="input-wrapper">
                                        <DatePickerInput
                                            onChange={(data: string) => {
                                                setData((prev: dataI) => ({
                                                    ...prev,
                                                    campus_tour: { ...prev.campus_tour, date: data }
                                                }));
                                                setCampusTourError(false);
                                            }}
                                            value={data.campus_tour.date}
                                            required={false}
                                            placeholder=""
                                            disabled={false}
                                            floatLabel="Meeting Date"
                                            filterDisabled={is1WeekDisabled}
                                        />
                                    </Form.Item>
                                    <div className="slot__row">
                                        <Form.Item className="input-wrapper pitch-time">
                                            <Select
                                                className="time-select"
                                                setChanged={() => {}}
                                                onChange={(data: string) => {
                                                    setData((prev: dataI) => ({
                                                        ...prev,
                                                        campus_tour: {
                                                            ...prev.campus_tour,
                                                            time_from: data,
                                                            time_to: ''
                                                        }
                                                    }));
                                                    generateDependentArray(data, true);
                                                    setCampusTourError(false);
                                                }}
                                                value={data.campus_tour.time_from}
                                                floatLabel="Time From"
                                                maxLength={60}
                                                required={false}
                                                hasError={() => !!form.getFieldError(['time_from']).length}
                                                options={timeArray}
                                                image={clock}
                                            />
                                        </Form.Item>
                                        <Form.Item className="input-wrapper pitch-time">
                                            <Select
                                                disabled={data.campus_tour.time_from ? false : true}
                                                setChanged={() => {}}
                                                onChange={(data: string) => {
                                                    setData((prev: dataI) => ({
                                                        ...prev,
                                                        campus_tour: { ...prev.campus_tour, time_to: data }
                                                    }));
                                                    setCampusTourError(false);
                                                }}
                                                value={data.campus_tour.time_to}
                                                floatLabel="Time To"
                                                maxLength={60}
                                                required={false}
                                                hasError={() => false}
                                                options={timeToArraysCT}
                                                image={clock}
                                            />
                                        </Form.Item>
                                    </div>
                                    <Form.Item className="input-wrapper">
                                        <FloatTextArea
                                            floatLabel="Campus Tour Instructions (Optional)"
                                            maxLength={512}
                                            hasError={() => false}
                                            value={data.campus_tour.instructions}
                                            onChange={(e: any) => {
                                                setData((prev: dataI) => ({
                                                    ...prev,
                                                    campus_tour: { ...prev.campus_tour, instructions: e.target.value }
                                                }));
                                                setCampusTourError(false);
                                            }}
                                            onBlur={() => {
                                                if (!data.arrival_point.trim()) {
                                                    setErrorsFields(true);
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Form>
                            </div>
                            {campusTourError && (
                                <div className="error-text" style={{ margin: '-15px 0 10px 0' }}>
                                    These are required fields
                                </div>
                            )}
                        </div>

                        <Divider />
                        <div className="main__pitchtitle">Duration of Supplier Pitch</div>
                        <div className="modal__duration">
                            <div
                                className={`modal__duration-item ${data.duration === 60 ? 'active' : ''}`}
                                onClick={() => {
                                    setData((prev: dataI) => ({ ...prev, duration: 60 }));
                                    setIsDirty(true);
                                }}
                            >
                                <ClockIcon fill="#808CB2" />
                                60 min
                            </div>
                            <div
                                className={`modal__duration-item ${data.duration === 90 ? 'active' : ''}`}
                                onClick={() => {
                                    setData((prev: dataI) => ({ ...prev, duration: 90 }));
                                    setIsDirty(true);
                                }}
                            >
                                <ClockIcon fill="#808CB2" />
                                90 min
                            </div>
                            <div
                                className={`modal__duration-item ${data.duration === 120 ? 'active' : ''}`}
                                onClick={() => {
                                    setData((prev: dataI) => ({ ...prev, duration: 120 }));
                                    setIsDirty(true);
                                }}
                            >
                                <ClockIcon fill="#808CB2" />
                                120 min
                            </div>
                        </div>

                        <Divider />
                        <div className="main__pitchtitle">
                            How many representatives from each supplier are permitted to attend the meeting?
                        </div>
                        <div className="modal__duration">
                            <div
                                className={`modal__duration-item people ${
                                    data.representatives_count === 2 ? 'active' : ''
                                }`}
                                onClick={() => {
                                    setData((prev: dataI) => ({ ...prev, representatives_count: 2 }));
                                    setIsDirty(true);
                                }}
                            >
                                <PeopleIcon fill="#808CB2" />2
                            </div>
                            <div
                                className={`modal__duration-item people ${
                                    data.representatives_count === 3 ? 'active' : ''
                                }`}
                                onClick={() => {
                                    setData((prev: dataI) => ({ ...prev, representatives_count: 3 }));
                                    setIsDirty(true);
                                }}
                            >
                                <PeopleIcon fill="#808CB2" />3
                            </div>
                            <div
                                className={`modal__duration-item people ${
                                    data.representatives_count === 4 ? 'active' : ''
                                }`}
                                onClick={() => {
                                    setData((prev: dataI) => ({ ...prev, representatives_count: 4 }));
                                    setIsDirty(true);
                                }}
                            >
                                <PeopleIcon fill="#808CB2" />4
                            </div>
                            <div
                                className={`modal__duration-item people ${
                                    data.representatives_count === 5 ? 'active' : ''
                                }`}
                                onClick={() => {
                                    setData((prev: dataI) => ({ ...prev, representatives_count: 5 }));
                                    setIsDirty(true);
                                }}
                            >
                                <PeopleIcon fill="#808CB2" />5
                            </div>
                        </div>
                        <Divider />
                        <div className="main__pitchtitle">
                            Is there anything specific you would like the supplier to prepare for, in addition to the
                            standard agenda?
                        </div>
                        <FloatTextArea
                            floatLabel="Please, enter a specific question"
                            maxLength={512}
                            hasError={() => {}}
                            value={data.specific_questions}
                            onChange={(e: any) => {
                                setData((prev: dataI) => ({ ...prev, specific_questions: e.target.value }));
                                setIsDirty(true);
                            }}
                        />
                        <div className="main__subtitle" style={{ margin: '8px 0 0 0' }}>
                            <span className="modal__boldtext">Important notes:</span> The standard agenda includes
                            company information, a presentation demonstrating how well they align with your ideal
                            supplier characteristics, a solution/product presentation, and an explanation of their
                            onboarding approach.
                        </div>
                        <Divider />
                        <div id="arrival"></div>
                        <FloatTextArea
                            floatLabel="Where should suppliers report to upon arrival at the school?"
                            maxLength={512}
                            hasError={() => errorsFields}
                            value={data.arrival_point}
                            onChange={(e: any) => {
                                setData((prev: dataI) => ({ ...prev, arrival_point: e.target.value }));
                                setErrorsFields(false);
                                setIsDirty(true);
                            }}
                            onBlur={() => {
                                if (!data.arrival_point.trim()) {
                                    setErrorsFields(true);
                                }
                            }}
                            required
                        />
                        {errorsFields ? <div className="error-text">This is a required field</div> : <></>}
                        <Divider />

                        <div className="main__pitchtitle">
                            Provide available slots for supplier pitches
                            <div className="modal__slotsleft">
                                {data?.slots.length}/{supplirsLength} left
                            </div>
                        </div>
                        <div className="modal__note-text">
                            <b>Important:</b> Schedule supplier pitches one week after a campus tour, if selected, to
                            allow suppliers to better tailor their presentations to your needs.
                        </div>
                        <div className="slot-list">
                            {data?.slots?.map((elem: SlotI, index: number) => {
                                return (
                                    <>
                                        <div className={`slot ${errors.includes(index) ? 'error' : ''}`}>
                                            <div className="slot__title">
                                                Slot {index + 1}
                                                <span onClick={() => deleteSlot(index)}>
                                                    <Delete fill="#E80A0F" style={{ cursor: 'pointer' }} />
                                                </span>
                                            </div>
                                            <Form className="slot__form">
                                                <Form.Item className="input-wrapper">
                                                    <DatePickerInput
                                                        onChange={(data: string) =>
                                                            handleInputChange(index, data, 'date')
                                                        }
                                                        value={elem.date}
                                                        required={true}
                                                        placeholder=""
                                                        disabled={false}
                                                        floatLabel="Meeting Date"
                                                        filterDisabled={is2WeekDisabled}
                                                    />
                                                </Form.Item>
                                                <div className="slot__row">
                                                    <Form.Item className="input-wrapper pitch-time">
                                                        <Select
                                                            className="time-select"
                                                            setChanged={() => {}}
                                                            onChange={(data: string) =>
                                                                handleInputChange(index, data, 'time_from')
                                                            }
                                                            value={elem.time_from}
                                                            floatLabel="Time From"
                                                            maxLength={60}
                                                            required={true}
                                                            hasError={() => !!form.getFieldError(['time_from']).length}
                                                            options={timeArray}
                                                            image={clock}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item className="input-wrapper pitch-time">
                                                        <Select
                                                            disabled={elem.time_from ? false : true}
                                                            setChanged={() => {}}
                                                            onChange={(data: string) =>
                                                                handleInputChange(index, data, 'time_to')
                                                            }
                                                            value={elem.time_to}
                                                            floatLabel="Time To"
                                                            maxLength={60}
                                                            required={true}
                                                            hasError={() => false}
                                                            options={timeToArrays}
                                                            image={clock}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </Form>
                                        </div>
                                        {errors.includes(index) && (
                                            <div className="error-text" style={{ margin: '-15px 0 10px 0' }}>
                                                These are required fields
                                            </div>
                                        )}
                                    </>
                                );
                            })}
                        </div>
                        {countOfSlotsError ? (
                            <div
                                className="error-text"
                                style={{ margin: '-15px 0 10px 0', textAlign: 'center', padding: '12px 0 0 0' }}
                            >
                                Please add a minimum of {supplirsLength} slots
                            </div>
                        ) : (
                            <></>
                        )}
                        <Button className="modal__duration-item slot" onClick={setSlotsFunc}>
                            <img src={addslotIcon} className="modal__duration-item-image" />
                            Add Slot
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
