import { Button, Empty, message, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { ModalSchool } from '../../../components/Modals/ModalSchool';
import { Key, SortOrder, TableRowSelection } from 'antd/es/table/interface';
import { School, SchoolDsQueryParams } from '../../../api/types/school';
import { useDeleteSchoolBulkMutation, useDeleteSchoolMutation, useGetSchoolDsQuery } from '../../../api/schools';
import { useTableDataSourceWithHooks } from '../../../components/Table/useTableDataPropsWithHooks';
import ArrowUp from '../../../components/Icons/ArrowUp';
import ArrowDown from '../../../components/Icons/ArrowDown';
import SchoolType from '../../../assets/SchoolType.svg';
import Delete from '../../../components/Icons/Delete';
import NoResults from '../../../components/Icons/NoResults';
import Path from '../../../assets/Province.svg';
import NoUsers from '../../../components/Icons/NoUsers';
import { Table } from '../../../components/Table';
import SchoolInfo from '../../../components/SchoolInfo';
import InputSearch from '../../../components/Fields/InputSearch';
import ModalDeleteSchool from '../../../components/Modals/ModalDeleteSchool';
import { colors } from '../../../themes/colors';
import useDebounceSearch from '../../../hooks/useDebounceSearch';
import { CustomSelect } from '../../../components/Fields/CustomSelect';

export const ManageSchools = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

    const [variables, setVariables] = useState<SchoolDsQueryParams>({
        filter: { search: undefined, status: undefined, type: undefined, province_id: undefined },
        sort_field: undefined,
        sort_direction: undefined,
        per_page: 20,
        page: 1
    });
    const [selectedSchoolType, setSelectedSchoolType] = useState<{ value: string; name: string }>({
        value: '',
        name: ''
    });
    const [selectedProvince, setSelectedProvince] = useState<{ value: string; name: string }>({
        value: '',
        name: ''
    });

    useEffect(() => {
        setVariables({
            ...variables,
            filter: {
                ...variables.filter,
                type: selectedSchoolType.value ? [selectedSchoolType.value] : undefined,
                province_id: selectedProvince?.value ? [selectedProvince?.value] : undefined
            }
        });
    }, [selectedSchoolType, selectedProvince]);

    const [deleteSchool, { isLoading: isDeleting, isError: isDeletingError, error: deletingError }] =
        useDeleteSchoolMutation();
    const [deleteSchoolBulk, { isLoading: isDeletingBulk, isError: isDeletingBulkError, error: deletingBulkError }] =
        useDeleteSchoolBulkMutation();
    const [editModal, setEditModal] = useState<{ type: string; open: boolean; id: number | null }>({
        type: 'view',
        open: false,
        id: null
    });
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounceSearch(search);

    useEffect(() => {
        setVariables({
            ...variables,
            filter: { ...variables.filter, search: debouncedSearch }
        });
    }, [debouncedSearch]);

    const [deleteModal, setDeleteModal] = useState<{ open: boolean; id: number | number[] | null; name: string }>({
        open: false,
        id: null,
        name: ''
    });
    const { data, isLoading, isFetching, refetch } = useGetSchoolDsQuery(variables);

    const SortIcon = ({ sortOrder }: { sortOrder: SortOrder }) => {
        if (sortOrder === 'ascend') {
            return <ArrowUp />;
        } else if (sortOrder === 'descend') {
            return <ArrowDown />;
        } else return <></>;
    };

    const { tableProps } = useTableDataSourceWithHooks<School>({
        columns: [
            {
                dataIndex: 'name',
                title: 'School Name',
                width: 350,
                sorter: true,
                sortIcon: SortIcon,
                render: (_, record) => <SchoolInfo avatarUrl={record?.preview || undefined} name={record?.name} />
            },
            {
                dataIndex: 'type',
                title: 'School Type',
                sorter: true,
                width: 150,
                render: (_, record) => <Typography.Text>{record?.type_name}</Typography.Text>,
                sortIcon: SortIcon
            },
            {
                dataIndex: 'city',
                title: 'City',
                sorter: true,
                width: 150,
                sortIcon: SortIcon
            },
            {
                dataIndex: 'province',
                title: 'Province',
                sorter: true,
                width: 150,
                sortIcon: SortIcon
            },
            {
                dataIndex: 'address',
                title: 'Address',
                width: 300
            },
            {
                dataIndex: 'phone_number',
                title: 'Telephone',
                width: 150
            },
            {
                title: ' ',
                width: 50,
                key: 'x',
                render: (_, record) => (
                    <Delete
                        style={{ fontSize: '20px' }}
                        fill={colors.Texts_and_Icons.Disabled}
                        onClick={(event) => {
                            event.stopPropagation();
                            setDeleteModal({ open: true, id: record.id, name: record.name });
                        }}
                    />
                ),
                align: 'right'
            }
        ],
        rowKey: 'id',
        data: {
            rows: data?.data,
            loading: isLoading || isFetching,
            total: data?.meta?.total
        },
        paginationConfig: { defaultPageSize: 20, showSizeChanger: false, position: ['bottomCenter'] },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                page: options?.page || 1,
                per_page: options?.limit || 20,
                sort_field: options?.sortField || variables.sort_field,
                sort_direction: options?.sortDirection || variables.sort_direction,
                filter: {
                    ...variables.filter
                }
            });
        }
    });

    useEffect(() => {
        if (isDeletingError) {
            //@ts-ignore
            messageApi.error(deletingError?.data.message);
        }
    }, [isDeletingError]);

    const handleDelete = async () => {
        try {
            await deleteSchool(deleteModal.id).unwrap();
            messageApi.success(`School ${deleteModal.name} has been deleted successfully`);
        } catch (e) {
        } finally {
            setDeleteModal({ open: false, id: null, name: '' });
            refetch();
        }
    };

    useEffect(() => {
        if (isDeletingBulkError) {
            messageApi.error('The User can not be deleted as they participate in an active tender');
        }
    }, [isDeletingBulkError]);

    const handleBulkDelete = async (ids: number[]) => {
        try {
            await deleteSchoolBulk({ ids }).unwrap();
            messageApi.success(`School ${deleteModal.name} has been deleted successfully`);
        } catch (e) {
        } finally {
            setDeleteModal({ open: false, id: null, name: '' });
            setSelectedRowKeys([]);
            refetch();
        }
    };

    const rowSelection: TableRowSelection<School> = {
        type: 'checkbox',
        selectedRowKeys,
        onChange: (selectedRowKeys: Key[], selectedRows: School[]) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        columnWidth: 35
    };

    const deleteModalCloseHandler = () => {
        setDeleteModal({ open: false, id: null, name: '' });
        refetch();
    };

    return (
        <div className="admin-panel__table-container">
            {contextHolder}
            <div className="header-actions">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '300px', margin: '-4px 0 0 15px' }}>
                        <InputSearch handleChange={(value) => setSearch(value)} />
                    </div>

                    <div style={{ width: '300px', margin: '0 0 0 15px' }}>
                        <CustomSelect
                            prefix={SchoolType}
                            isSearch={false}
                            search={''}
                            floatLabel="School Type"
                            required={false}
                            err={false}
                            setSearch={() => {}}
                            options={[
                                { value: 'public', name: 'Public' },
                                { value: 'private', name: 'Private' },
                                { value: 'former_model_c', name: 'Former Model C' }
                            ]}
                            onChange={(value: any) => {
                                setSelectedSchoolType(value);
                            }}
                            value={selectedSchoolType?.name}
                            form={''}
                            disabled={false}
                            hasError={() => false}
                        />
                    </div>

                    <div style={{ width: '300px', margin: '0 0 0 15px' }}>
                        <CustomSelect
                            prefix={Path}
                            isSearch={false}
                            search={''}
                            floatLabel="Province"
                            required={false}
                            err={false}
                            setSearch={() => {}}
                            options={[
                                { value: 1, name: 'Eastern Cape' },
                                { value: 2, name: 'Free State' },
                                { value: 3, name: 'Gauteng' },
                                { value: 4, name: 'KwaZulu-Natal' },
                                { value: 5, name: 'Limpopo' },
                                { value: 6, name: 'Mpumalanga' },
                                { value: 7, name: 'North West' },
                                { value: 8, name: 'Northern Cape' },
                                { value: 9, name: 'Western Cape' }
                            ]}
                            onChange={(value: any) => {
                                setSelectedProvince(value);
                            }}
                            value={selectedProvince?.name}
                            form={''}
                            disabled={false}
                            hasError={() => false}
                        />
                    </div>
                </div>

                <Button
                    style={{ width: '107px' }}
                    type="primary"
                    onClick={() => {
                        setEditModal({ type: 'create', open: true, id: null });
                    }}
                    icon={<PlusCircleOutlined />}
                >
                    Add
                </Button>
            </div>

            <Table<School>
                {...tableProps}
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                scroll={{ y: '70vh' }}
                handleDelete={() =>
                    setDeleteModal({ open: true, id: selectedRowKeys as number[], name: 'selected schools' })
                }
                onRow={(record) => {
                    return {
                        onClick: () => {
                            setEditModal({ type: 'view', open: true, id: record.id });
                        }
                    };
                }}
                // TODO: move to separate component
                locale={{
                    emptyText: variables.filter?.search ? (
                        <Empty
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                gap: '24px',
                                margin: '50px 0'
                            }}
                            image={<NoResults />}
                            description={
                                <div
                                    style={{
                                        maxWidth: '390px'
                                    }}
                                >
                                    <Typography.Title level={4}>No results found</Typography.Title>
                                    <Typography.Paragraph type="secondary">
                                        Try adjusting your search to find what you are looking for
                                    </Typography.Paragraph>
                                </div>
                            }
                        />
                    ) : (
                        <Empty
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                gap: '24px',
                                margin: '50px 0'
                            }}
                            image={<NoUsers />}
                            description={
                                <div
                                    style={{
                                        maxWidth: '390px'
                                    }}
                                >
                                    <Typography.Title level={4}>No Schools yet</Typography.Title>
                                    <Typography.Paragraph type="secondary">
                                        Add a new school to start to working with them
                                    </Typography.Paragraph>
                                    <Button
                                        style={{ width: '107px' }}
                                        type="primary"
                                        onClick={() => {
                                            setEditModal({ type: 'create', open: true, id: null });
                                        }}
                                        icon={<PlusCircleOutlined />}
                                    >
                                        Add
                                    </Button>
                                </div>
                            }
                        />
                    )
                }}
            />

            {editModal.open && (
                <ModalSchool
                    openModal={editModal.open}
                    setOpenModal={setEditModal}
                    type={editModal.type}
                    id={editModal.id}
                    refetch={() => refetch()}
                />
            )}
            {deleteModal.open && (
                <ModalDeleteSchool
                    open={deleteModal.open}
                    onClose={deleteModalCloseHandler}
                    id={deleteModal.id}
                    onDelete={handleDelete}
                    onDeleteBulk={handleBulkDelete}
                    name={deleteModal.name}
                    isDeleting={isDeleting || isDeletingBulk}
                />
            )}
        </div>
    );
};
