import React, { useEffect } from 'react';
import { Button, message, Modal, Skeleton } from 'antd';
import { useDeleteUserBulkMutation } from '../../api/users';

import close from '../../assets/RoundedClose.svg';

interface ModalDeleteUserProps {
    open: boolean;
    userId: number | number[] | null;
    onClose: () => void;
    deleteUser: () => void;
    deleteUserBulk: (ids: number[]) => void;
    isDeleting: boolean;
    userName?: string;
}

const ModalDeleteUser = ({
    open,
    onClose,
    userId,
    userName,
    deleteUser,
    deleteUserBulk,
    isDeleting
}: ModalDeleteUserProps) => {
    const [
        deleteUserBulkCheck,
        { isLoading: isDeletingBulkCheck, isError: isDeletingBulkCheckError, error: deletingBulkCheckError }
    ] = useDeleteUserBulkMutation();
    const [messageApi, contextHolder] = message.useMessage();

    const deleteCheck = async () => {
        try {
            await deleteUserBulkCheck({ ids: userId as number[], action: 'check' }).unwrap();
        } catch (e: any) {
            if (e?.status === 422) {
            } else {
                messageApi.error('Something went wrong, please try again later');
            }
        }
    };

    useEffect(() => {
        if (Array.isArray(userId)) {
            deleteCheck();
        }
    }, [open, userId]);

    return (
        <>
            {contextHolder}
            <Modal
                centered
                open={open}
                closeIcon={<img src={close} alt={'close'} />}
                width={600}
                onCancel={() => onClose()}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" className="cancel" onClick={() => onClose()} block>
                            Cancel
                        </Button>
                        <Button
                            className="quit"
                            onClick={() => {
                                Array.isArray(userId) && userId.length > 1 ? deleteUserBulk(userId) : deleteUser();
                            }}
                            loading={isDeleting || isDeletingBulkCheck}
                            block
                        >
                            Delete
                        </Button>
                    </div>
                }
            >
                <div className="modal__title">Delete user?</div>
                {userName && (
                    <div className="modal-confirm modal-confirm__delete">
                        Are you sure you want to delete <strong>{`${userName}`}</strong>?
                    </div>
                )}
                {isDeletingBulkCheckError && (
                    <div className="modal-confirm modal-confirm__delete">
                        The system will delete users <strong>except</strong> the ones that participate in an
                        <strong> active tender</strong>:{/*@ts-ignore*/}
                        {deletingBulkCheckError?.data?.excluded.map((item) => (
                            <p>{item}</p>
                        ))}
                    </div>
                )}
            </Modal>
        </>
    );
};

export default ModalDeleteUser;
