import { useRef, useState, ReactNode } from 'react';
import { InputProps, Input, Form } from 'antd';
import './index.scss';
import { setConstantValue } from 'typescript';

interface FloatInputProps extends InputProps {
    floatLabel: string | ReactNode;
    setChanged?: any;
    name?: string;
    error?: boolean;
    onBlur?: any;
    onChange?: any;
    onKeyPress?: any;
    hasError?: any;
    minRows?: number;
    onKeyDown?: any;
}

export const FloatTextArea = (props: FloatInputProps) => {
    const [focus, setFocus] = useState(false);
    let {
        floatLabel,
        placeholder,
        className,
        disabled,
        setChanged,
        name,
        error,
        onBlur,
        onChange,
        maxLength,
        onKeyPress,
        hasError,
        minRows = 3,
        onKeyDown
    } = props;

    if (!placeholder) {
        //@ts-ignore
        placeholder = floatLabel;
    }

    const { TextArea } = Input;

    const isOccupied = focus || props.value;

    const labelClass = isOccupied ? 'label as-label ' : 'label as-placeholder';

    const requiredMark = props.required ? <span className="red">*</span> : null;

    return (
        <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
            <div
                className={`textarea ${focus ? 'focus' : ''} ${hasError() ? 'red' : ''} `}
                onBlur={() => setFocus(false)}
                onFocus={() => setFocus(true)}
            >
                <TextArea
                    onBlur={onBlur}
                    disabled={disabled}
                    rows={3}
                    autoSize={{ minRows: minRows, maxRows: 6 }}
                    value={props.value}
                    onChange={onChange}
                    maxLength={maxLength}
                    bordered={false}
                    onKeyPress={onKeyPress}
                    onKeyDown={onKeyDown}
                />
            </div>

            <label className={labelClass} style={disabled ? { display: 'none' } : {}}>
                {isOccupied ? floatLabel : placeholder} {requiredMark}
            </label>
        </div>
    );
};
