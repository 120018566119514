import { useState, FC, useEffect } from 'react';
import {
    useDeleteFileMutation,
    useResetActionMutation,
    useSendTaskMutation,
    useUpdateTaskDeadlineMutation
} from '../../api/tenders';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { Button, Modal, Tooltip, message } from 'antd';
import { useAppSelector } from '../../hooks';
import { Loader } from '../Loader/Loader';
import { TaskDeadlineModal } from '../Modals/TaskDeadline';
import { DesignDocumentTask } from './DesignPhaseComponents/DesignDocumentTask';

import arrow from '../../assets/arrow-right-white.svg';
import attention from '../../assets/attention.svg';
import file from '../../assets/document.svg';

import moment from 'moment';
import './phases.scss';

interface PropsPhase {
    data: tenderDataProps;
    trigger: boolean;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DesignPhase: FC<PropsPhase> = ({ data, trigger, setTrigger, setConfirmModal }) => {
    const { role } = useAppSelector((state) => state.profileReducer);
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);

    const [
        deleteFile,
        {
            data: deletedData,
            isLoading: isLoadingDeleteFile,
            isSuccess: isSuccessDeleteFile,
            isError: isErrorDeleteFile
        }
    ] = useDeleteFileMutation();

    const [
        sendTask,
        { data: dataSendTask, isError: isErrorSendTask, isSuccess: isSuccessSendTask, isLoading: isLoadingSendTask }
    ] = useSendTaskMutation();

    const [updateDeadline, { isLoading }] = useUpdateTaskDeadlineMutation();

    const [resetAction, { isLoading: isLoadingResetAction }] = useResetActionMutation();

    const [isDeleteFileModal, setIsDeleteFileModal] = useState<boolean>(false);
    const [deadlineModal, setDeadlineModal] = useState(false);
    const [newDocumentModal, setNewDocumentModal] = useState<boolean>(false);
    const [documentModal, setDocumentModal] = useState<boolean>(false);

    const [taskId, setTaskId] = useState<string | null | number>(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [SelectedDate, setSelectedDate] = useState<Date | string>(new Date());
    const [confirmMeeting, setConfirmMeeting] = useState(false);
    const [isShowAddDocument, setIsShowAddDocument] = useState(false);
    const [isShowDeadlineForTR, setIsShowDeadlinaForTR] = useState(false);

    useEffect(() => {
        if (data?.phase_tasks.length) {
            data?.phase_tasks.forEach((elem) => {
                if (
                    elem.status === 'pending_tr' ||
                    elem.status === 'need_resend_tr' ||
                    data.can_send_task ||
                    data.can_update_phase
                ) {
                    setIsShowDeadlinaForTR(true);
                }
                if (elem.status === 'pending_su') {
                    setIsShowDeadlinaForTR(false);
                }
            });
        } else {
            setIsShowDeadlinaForTR(true);
        }
    }, [data, role]);

    useEffect(() => {
        if (data.phase_tasks.length) {
            data.phase_tasks.forEach((elem) => {
                if (elem.status === 'pending_tr' || elem.status === 'need_resend_tr' || data.can_update_phase) {
                    setIsShowAddDocument(true);
                }
                if (elem.status === 'pending_su') {
                    setIsShowAddDocument(false);
                }
            });
        } else {
            setIsShowAddDocument(true);
        }
    }, [data, role]);

    useEffect(() => {
        if (!data) return;
        if (data.tasks_deadline_at) {
            setSelectedDate(data.tasks_deadline_at);
        }
    }, [data]);

    const updateDeadlineFunc = async () => {
        try {
            const formData = {
                id: data.id,
                data: { deadline_at: moment(SelectedDate).format('YYYY-MM-DD') }
            };
            await updateDeadline(formData).unwrap();
            await setDeadlineModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const sendTaskFunc = async () => {
        if (data?.phase_tasks[0].action === 'design_document') {
            if (role !== 'school_user') {
                let formData = {};

                formData = {
                    id: data?.id
                };
                try {
                    await sendTask(formData).unwrap();
                    await setTrigger((prev: boolean) => !prev);
                    await setConfirmMeeting(false);
                } catch {}
            }
            if (role === 'school_user') {
                let formData = {
                    id: data?.id
                };
                try {
                    await sendTask(formData).unwrap();
                    await setTrigger((prev: boolean) => !prev);
                } catch {}
            }
        }
    };

    useEffect(() => {
        if (isErrorSendTask) {
            messageApi.error('Something went wrong, please try again');
        }
        if (isSuccessSendTask) {
            messageApi.success(dataSendTask.message);
        }
    }, [isErrorSendTask, isSuccessSendTask]);

    useEffect(() => {
        if (isSuccessDeleteFile) {
            messageApi.success(deletedData.message);
        }
        if (isErrorDeleteFile) {
            messageApi?.error('Something went wrong, please try again');
        }
    }, [isSuccessDeleteFile, isErrorDeleteFile]);

    const deleteFileFunc = async () => {
        if (role === 'school_user') {
            const index = data.phase_tasks.findIndex((elem) => elem.id === taskId);
            const element = data.phase_tasks[index];

            try {
                const formData: any = {
                    tenderId: data.id,
                    taskId: taskId
                };
                if (!element.files.school_user?.[0]?.name) {
                    formData.data = { action: 'decline' };
                    await resetAction(formData).unwrap();
                }
                if (element.files.school_user) {
                    formData.file =
                        data?.phase_tasks?.find((elem: any) => elem.id === taskId)?.files.school_user[0].uuid || null;

                    await deleteFile(formData).unwrap();
                }

                await setIsDeleteFileModal(false);
                await setTrigger((prev: boolean) => !prev);
            } catch {}
        } else {
            try {
                const formData = {
                    tenderId: data.id,
                    taskId: taskId,
                    file:
                        data?.phase_tasks?.find((elem: any) => elem.id === taskId)?.files.tender_representative[0]
                            .uuid || null
                };
                await deleteFile(formData).unwrap();
                await setIsDeleteFileModal(false);
                await setTrigger((prev: boolean) => !prev);
            } catch {}
        }
    };

    return (
        <>
            {contextHolder}
            <div className={`tendermain__block tasks`}>
                {role !== 'school_user' && (
                    <div className="tasks__header define">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>
                        {isShowDeadlineForTR && (
                            <div className="tasks__deadline-wrapper">
                                <img src={attention} />
                                {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                {moment(data?.phase_deadline_at).format('YYYY-MM-DD')}
                            </div>
                        )}
                    </div>
                )}
                {role === 'school_user' && (
                    <div className="tasks__header design">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>
                        {data?.phase_tasks?.[0]?.action === 'design_document' &&
                            data?.phase_tasks?.[0]?.status === 'pending_su' && (
                                <div className="tasks__deadline-wrapper">
                                    <img src={attention} />
                                    {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                    {moment(data?.tasks_deadline_at).format('YYYY-MM-DD')}
                                </div>
                            )}
                    </div>
                )}

                <div className="tasks__list">
                    <DesignDocumentTask
                        data={data}
                        role={role}
                        setTaskId={setTaskId}
                        taskId={taskId}
                        messageApi={messageApi}
                        setTrigger={setTrigger}
                        newDocumentModal={newDocumentModal}
                        documentModal={documentModal}
                        setNewDocumentModal={setNewDocumentModal}
                        setDocumentModal={setDocumentModal}
                        setDeadlineModal={setDeadlineModal}
                        setIsDeleteFileModal={setIsDeleteFileModal}
                    />
                </div>
                {role === 'tender_representative' && (
                    <div className="tasks__btn-wrap">
                        {isShowAddDocument ? (
                            <div
                                className={`tasks__add_supporting`}
                                onClick={() => {
                                    setNewDocumentModal(true);
                                }}
                            >
                                <img src={file} />
                                Add Document
                            </div>
                        ) : (
                            <div></div>
                        )}
                        {data.can_update_phase ? (
                            <Tooltip
                                title="To move to the next Phase, you need to Complete all the tasks"
                                overlayClassName={` ${
                                    !data?.can_update_phase ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_update_phase ? '' : 'disabled'}`}
                                    type="primary"
                                    onClick={() => {
                                        if (data?.can_update_phase) {
                                            setConfirmModal(true);
                                        } else {
                                            return;
                                        }
                                    }}
                                >
                                    Next Phase <img src={arrow} />
                                </Button>
                            </Tooltip>
                        ) : (
                            <Tooltip
                                style={{ width: '250px' }}
                                title={
                                    isShowAddDocument
                                        ? !data?.can_send_task && data?.tasks_deadline_at
                                            ? 'To send this task, you need complete all Task Activities'
                                            : 'To send this task, please set a deadline for the school user first.'
                                        : ''
                                }
                                overlayClassName={` ${
                                    !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${!data?.can_send_task ? 'disabled' : ''}`}
                                    type="primary"
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    {isLoadingSendTask ? (
                                        <Loader />
                                    ) : (
                                        <div className="flex">
                                            Send Task <img src={arrow} style={{ margin: '0 0 0 10px' }} />
                                        </div>
                                    )}
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                )}
                {role === 'school_user' && (
                    <>
                        {data.phase_tasks[0]?.action === 'design_document' && (
                            <div className="tasks__btn-wrap">
                                <div></div>
                                <Tooltip
                                    title={
                                        data.phase_tasks[0]?.status !== 'pending_su'
                                            ? ''
                                            : 'To Send Task, you need to Complete all activities'
                                    }
                                    overlayClassName={` ${
                                        !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                    }`}
                                >
                                    <Button
                                        className={`tasks__next-btn ${!data?.can_send_task ? 'disabled' : ''}`}
                                        type="primary"
                                        onClick={() => {
                                            if (!data?.can_send_task) {
                                                return;
                                            } else {
                                                if (!isLoadingSendTask) {
                                                    sendTaskFunc();
                                                }
                                            }
                                        }}
                                    >
                                        {isLoadingSendTask ? (
                                            <Loader />
                                        ) : (
                                            <div className="flex">
                                                Send Task <img src={arrow} style={{ margin: '0 0 0 10px' }} />
                                            </div>
                                        )}
                                    </Button>
                                </Tooltip>
                            </div>
                        )}
                    </>
                )}
            </div>

            {confirmMeeting && (
                <Modal
                    className="confirmModal"
                    centered
                    open={confirmMeeting}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setConfirmMeeting(false)} block className="cancel">
                                Cancel
                            </Button>
                            <Button type="primary" style={{ width: '100%' }} onClick={sendTaskFunc}>
                                {isLoadingSendTask ? <Loader /> : 'Confirm'}
                            </Button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to schedule the meeting on <span>{data?.phase_tasks[0]?.date}</span>{' '}
                            from <span>{data?.phase_tasks[0]?.time_from}</span> to{' '}
                            <span>{data?.phase_tasks[0]?.time_to}</span>?
                        </div>
                    </div>
                </Modal>
            )}

            {isDeleteFileModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteFileModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteFileModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button
                                className="quit"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (!isLoadingDeleteFile && !isLoadingResetAction) {
                                        deleteFileFunc();
                                    }
                                }}
                            >
                                {isLoadingDeleteFile ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to delete the file?
                        </div>
                    </div>
                </Modal>
            )}

            {deadlineModal && (
                <TaskDeadlineModal
                    openModal={deadlineModal}
                    setOpenModal={setDeadlineModal}
                    SelectedDate={SelectedDate}
                    setSelectedDate={setSelectedDate}
                    updateDeadlineFunc={updateDeadlineFunc}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};
