import React from 'react';
import { Input } from 'antd';
import search from '../../assets/Search.svg';
import close from '../../assets/Close.svg';

interface InputSearchProps {
    handleChange: (value: string) => void;
}

const InputSearch = ({ handleChange }: InputSearchProps) => {
    const [value, setValue] = React.useState<string>('');

    return (
        <Input
            value={value}
            prefix={<img src={search} alt="search icon" />}
            allowClear={{
                clearIcon: (
                    <img
                        src={close}
                        style={{ margin: '20px 0 0 0', cursor: 'pointer' }}
                        onClick={() => {
                            handleChange('');
                            setValue('');
                        }}
                        alt="clear icon"
                    />
                )
            }}
            className="input search"
            style={{
                height: '40px',
                padding: '0px 20px 0 18px',
                fontSize: '16px',
                margin: '10px 0',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px'
            }}
            placeholder="Search"
            required={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e.target.value);
                setValue(e.target.value);
            }}
            maxLength={100}
        />
    );
};

export default InputSearch;
