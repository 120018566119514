import { useRef, useState } from 'react';
import { InputProps, Input, Form } from 'antd';
import './index.scss';

interface FloatInputProps extends InputProps {
    floatLabel: string;
    isContractValue?: boolean;
    setContractValue?: any;
    contractValue?: string;
    setChanged?: React.Dispatch<React.SetStateAction<boolean>>;
    name?: string;
    error?: boolean;
    onBlur?: any;
    maxLength?: number;
}

export const FloatInput = (props: FloatInputProps) => {
    const [focus, setFocus] = useState(false);
    let {
        floatLabel,
        placeholder,
        className,
        disabled,
        isContractValue = false,
        contractValue,
        setContractValue,
        setChanged,
        name,
        error,
        onBlur,
        maxLength = 11,
        ...restProps
    } = props;
    if (!placeholder) placeholder = floatLabel;
    const inputRef = useRef<any>(null);

    const isOccupied = focus || props.value || contractValue;

    const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

    const requiredMark = props.required ? <span className="red">*</span> : null;

    const currencyMask = (e: any) => {
        let value = e.target.value;
        value = value?.replace(/\D/g, '');
        value = value?.replace(/(\d)(\d{3})$/, '$1,$2');
        value = value?.replace(/(?=(\d{3})+(\D))\B/g, ',');
        e.target.value = value;
        return e.target.value;
    };

    return (
        <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
            {props.type === 'password' ? (
                <Input.Password {...restProps} className={className} onBlur={onBlur} />
            ) : props.type === 'contractValue' ? (
                <>
                    {(contractValue || focus) && <div className="R">R</div>}
                    <Input
                        {...restProps}
                        className={`${className} ${disabled ? 'disable' : ''} ${
                            error ? 'err' : ''
                        } contract-value-input`}
                        disabled={disabled}
                        onBlur={onBlur}
                        maxLength={maxLength}
                        value={contractValue}
                        onChange={(e) => {
                            setContractValue(currencyMask(e));
                            setChanged && setChanged(true);
                        }}
                    />
                </>
            ) : (
                <Input
                    {...restProps}
                    className={`${className} ${disabled ? 'disable' : ''} ${error ? 'err' : ''}`}
                    disabled={disabled}
                    maxLength={maxLength}
                />
            )}
            <label className={labelClass} style={disabled ? { display: 'none' } : {}}>
                {isOccupied ? floatLabel : placeholder} {requiredMark}
            </label>
            {isContractValue && (focus || contractValue) && (
                <div className="float-label__contract">
                    R
                    <Input maxLength={maxLength} />
                </div>
            )}
        </div>
    );
};
