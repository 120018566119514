import React, { useEffect, useState } from 'react';
import { Button, Empty, message, Select, Typography } from 'antd';
import { Table } from '../../../../components/Table';
import { useDeleteUserBulkMutation, useDeleteUserMutation, useGetUserDsQuery } from '../../../../api/users';
import { User, UserDsQueryParams } from '../../../../api/types/user';
import { Key, TableRowSelection } from 'antd/es/table/interface';
import ArrowUp from '../../../../components/Icons/ArrowUp';
import ArrowDown from '../../../../components/Icons/ArrowDown';
import UserStatus from '../../../../components/UserStatus';
import Delete from '../../../../components/Icons/Delete';
import { ModalAdmin } from '../../../../components/Modals/ModalAdmin';
import { useTableDataSourceWithHooks } from '../../../../components/Table/useTableDataPropsWithHooks';
import { PlusCircleOutlined } from '@ant-design/icons';
import UserInfo from '../../../../components/UserInfo';
import InputSearch from '../../../../components/Fields/InputSearch';
import NoResults from '../../../../components/Icons/NoResults';
import NoUsers from '../../../../components/Icons/NoUsers';
import ModalDeleteUser from '../../../../components/Modals/ModalDeleteUser';
import close from '../../../../assets/Close.svg';
import { colors } from '../../../../themes/colors';
import useDebounceSearch from '../../../../hooks/useDebounceSearch';
import { FloatSelect } from '../../../../components/Fields/FloatSelect';
import { CustomSelect } from '../../../../components/Fields/CustomSelect';
import RoleImage from '../../../../assets/RoleImage.svg';
import { CustomErrorType } from '../../../../api/types/global';

export const SchoolAdvisors = () => {
    const [modal, setModal] = useState<{ type: string; open: boolean; id: number | null }>({
        type: 'edit',
        open: false,
        id: null
    });
    const [deleteModal, setDeleteModal] = useState<{ open: boolean; id: number | number[] | null; name: string }>({
        open: false,
        id: null,
        name: ''
    });

    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounceSearch(search);

    const [selectedRole, setSelectedRole] = useState<{ value: string[]; name: string }>({
        value: ['tender_representative', 'super_admin'],
        name: ''
    });

    const [variables, setVariables] = useState<UserDsQueryParams>({
        filter: { role: ['tender_representative', 'super_admin'], search: undefined, status: undefined },
        sort_field: undefined,
        sort_direction: undefined,
        per_page: 20,
        page: 1
    });

    useEffect(() => {
        if (!selectedRole.value[0]) {
            setVariables({
                ...variables,
                filter: {
                    ...variables.filter,
                    search: debouncedSearch,
                    role: ['tender_representative', 'super_admin']
                }
            });
        } else {
            setVariables({
                ...variables,
                filter: {
                    ...variables.filter,
                    search: debouncedSearch,
                    role: selectedRole.value
                }
            });
        }
    }, [debouncedSearch, selectedRole]);

    const [messageApi, contextHolder] = message.useMessage();
    const { data, isLoading, isFetching, refetch } = useGetUserDsQuery(variables);
    const [deleteUser, { isLoading: isDeleting, isError: isDeletingError, error: deletingError }] =
        useDeleteUserMutation();
    const [deleteUserBulk, { isLoading: isDeletingBulk, isError: isDeletingBulkError, error: deletingBulkError }] =
        useDeleteUserBulkMutation();
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

    const deleteModalCloseHandler = () => {
        setDeleteModal({ open: false, id: null, name: '' });
        refetch();
    };

    const { tableProps } = useTableDataSourceWithHooks<User>({
        columns: [
            {
                dataIndex: 'full_name',
                title: 'Name',
                sorter: true,
                width: 350,
                sortIcon: ({ sortOrder }) => {
                    if (sortOrder === 'ascend') {
                        return <ArrowUp />;
                    }
                    if (sortOrder === 'descend') {
                        return <ArrowDown />;
                    }
                },
                render: (_, record) => (
                    <UserInfo
                        avatarUrl={record?.avatar_thumb}
                        fullName={record?.full_name}
                        email={record.email}
                        fullNameFallback={record.role === 'tender_representative' ? 'TR' : 'SA'}
                    />
                )
            },
            {
                dataIndex: 'role_name',
                title: 'Role',
                width: 200,
                render: (role_name, record) => (
                    <span className={record.role === 'super_admin' ? 'role-admin' : 'role-tr'}>{role_name}</span>
                )
            },
            {
                dataIndex: 'status',
                title: 'Status',
                width: 150,
                sorter: true,
                render: (status, record) => <UserStatus status={status} text={record.status_name} />,
                sortIcon: ({ sortOrder }) => {
                    if (sortOrder === 'ascend') {
                        return <ArrowUp />;
                    }
                    if (sortOrder === 'descend') {
                        return <ArrowDown />;
                    }
                }
            },
            {
                title: ' ',
                key: 'x',
                width: 50,
                render: (_, record) => (
                    <Delete
                        style={{ fontSize: '20px' }}
                        fill={colors.Texts_and_Icons.Disabled}
                        onClick={(event) => {
                            event.stopPropagation();
                            setDeleteModal({ open: true, id: record.id, name: record.full_name || record.email });
                        }}
                    />
                ),
                align: 'right'
            }
        ],
        rowKey: 'id',
        data: {
            rows: data?.data,
            loading: isLoading || isFetching,
            total: data?.meta?.total
        },
        paginationConfig: { defaultPageSize: 20, showPrevNextJumpers: true, position: ['bottomCenter'] },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                page: options?.page || 1,
                per_page: options?.limit || 20,
                sort_field: options?.sortField || variables.sort_field,
                sort_direction: options?.sortDirection || variables.sort_direction,
                filter: {
                    ...variables.filter
                }
            });
        }
    });

    const rowSelection: TableRowSelection<User> = {
        type: 'checkbox',
        selectedRowKeys,
        onChange: (selectedRowKeys: Key[], selectedRows: User[]) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        columnWidth: 20
    };

    useEffect(() => {
        if (isDeletingError) {
            messageApi.error('The User can not be deleted as they participate in an active tender');
        }
    }, [isDeletingError]);

    const handleDelete = async () => {
        try {
            await deleteUser(deleteModal.id).unwrap();
            messageApi.success(`User ${deleteModal.name} has been deleted successfully`);
        } catch (e) {
        } finally {
            setDeleteModal({ open: false, id: null, name: '' });
            refetch();
        }
    };

    const handleBulkDelete = async (ids: number[]) => {
        try {
            await deleteUserBulk({ ids }).unwrap();
            messageApi.success(`User ${deleteModal.name} has been deleted successfully`);
        } catch (e) {
            if (isDeletingBulkError) {
                messageApi.error((deletingBulkError as CustomErrorType)?.data?.message || 'Something went wrong');
            } else {
                console.error(e);
                messageApi.error('Something went wrong');
            }
        } finally {
            setDeleteModal({ open: false, id: null, name: '' });
            setSelectedRowKeys([]);
            refetch();
        }
    };

    return (
        <>
            {contextHolder}
            <div className="header-actions">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <InputSearch handleChange={(value) => setSearch(value)} />
                    <div style={{ width: '320px', margin: '7px 0 0 17px' }}>
                        <CustomSelect
                            prefix={RoleImage}
                            isSearch={false}
                            search={''}
                            floatLabel="Role"
                            required={false}
                            err={false}
                            setSearch={() => {}}
                            options={[
                                { value: 'super_admin', name: 'Super Admin' },
                                { value: 'tender_representative', name: 'Tender Representative' },
                                { value: 'onboarding_representative', name: 'Onboarding Repres.' }
                            ]}
                            onChange={(value: any) => {
                                setSelectedRole({ value: [value.value], name: value.name });
                            }}
                            value={selectedRole?.name}
                            form={''}
                            disabled={false}
                            hasError={() => false}
                        />
                    </div>
                </div>

                <Button
                    type="primary"
                    onClick={() => setModal({ type: 'create', open: true, id: null })}
                    icon={<PlusCircleOutlined />}
                >
                    Add User
                </Button>
            </div>
            <Table<User>
                {...tableProps}
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                scroll={{ y: '70vh' }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            if (record.status === 'pending') {
                                setModal({ type: 'view_pending', open: true, id: record.id });
                            } else {
                                setModal({ type: 'view', open: true, id: record.id });
                            }
                        }
                    };
                }}
                handleDelete={() =>
                    setDeleteModal({ open: true, id: selectedRowKeys as number[], name: 'selected users' })
                }
                locale={{
                    emptyText: variables.filter?.search ? (
                        <Empty
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                gap: '24px',
                                margin: '50px 0'
                            }}
                            image={<NoResults />}
                            description={
                                <div
                                    style={{
                                        maxWidth: '390px'
                                    }}
                                >
                                    <Typography.Title level={4}>No results found</Typography.Title>
                                    <Typography.Paragraph type="secondary">
                                        Try adjusting your search to find what you are looking for
                                    </Typography.Paragraph>
                                </div>
                            }
                        />
                    ) : (
                        <Empty
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                gap: '24px',
                                margin: '50px 0'
                            }}
                            image={<NoUsers />}
                            description={
                                <div
                                    style={{
                                        maxWidth: '390px'
                                    }}
                                >
                                    <Typography.Title level={4}>No users yet</Typography.Title>
                                    <Typography.Paragraph type="secondary">
                                        Add a new user to start to working with them
                                    </Typography.Paragraph>
                                    <Button
                                        style={{ width: '107px' }}
                                        type="primary"
                                        onClick={() => setModal({ type: 'create', open: true, id: null })}
                                        icon={<PlusCircleOutlined />}
                                    >
                                        Add User
                                    </Button>
                                </div>
                            }
                        />
                    )
                }}
            />
            {modal.open && (
                <ModalAdmin
                    openModal={modal.open}
                    setOpenModal={setModal}
                    type={modal.type}
                    userId={modal.id}
                    refetch={() => refetch()}
                />
            )}
            <ModalDeleteUser
                open={deleteModal.open}
                onClose={deleteModalCloseHandler}
                userId={deleteModal.id}
                deleteUser={handleDelete}
                deleteUserBulk={handleBulkDelete}
                userName={deleteModal.name}
                isDeleting={isDeleting || isDeletingBulk}
            />
        </>
    );
};
